import * as React from "react";
import { useLocation } from "@reach/router";
import ReactToPrint from "react-to-print";
import WorkoutSections from "../../components/WeeklyWorkout/WorkoutSections";

export default function PrintPage() {
  const location = useLocation();
  const componentRef = React.useRef();

  if (location.state?.content) {
    const content = JSON.parse(location.state?.content);
    return (
      <div style={{ padding: "2rem" }}>
        <ReactToPrint
          trigger={() => {
            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
            // to the root node of the returned component as it will be overwritten.
            return <button>Print this out!</button>;
          }}
          content={() => componentRef.current}
        />
        <div
          className="new-workout-detail print"
          ref={componentRef}
          style={{ paddingLeft: "2rem" }}
        >
          <WorkoutSections {...{ content, title: "test" }} />
        </div>
      </div>
    );
  }

  return "Oops, couldn't find workout content";
}
