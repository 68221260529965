import * as React from "react";
import { Link } from "gatsby-plugin-modal-routing-3";
import EmbedYouTubeVideo from "../EmbedYouTubeVideo";

export default function WorkoutSections({ content, title }) {
  const sections = content.map((item) => {
    const nodes = item.content.map((item, index) => {
      const key = `section-${title}-${index}`;
      if (item.type === "text") {
        return (
          <div {...{ key }}>
            <span>{item.value}</span>
          </div>
        );
      } else if (item.type === "bullets") {
        return (
          <ul {...{ key }}>
            {item.value.map((x, bulletIndex) => (
              <li key={`section-${title}-${index}-${bulletIndex}`}>{x}</li>
            ))}
          </ul>
        );
      } else if (item.type === "link") {
        const { href, title } = item.value;
        return (
          <a
            href={href}
            className="light-link"
            target="_blank"
            rel="noopener noreferrer"
            {...{ key }}
          >
            {title}
          </a>
        );
      } else if (item.type === "video") {
        const { href, title } = item.value;
        if (href.substr(0, 4) === "http") {
          if (href.substr(0, 13) === "https://youtu") {
            return <EmbedYouTubeVideo title={title} url={href} {...{ key }} />;
          }

          return (
            <a
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              {...{ key }}
            >
              {title}
            </a>
          );
        }

        return (
          <Link className="light-link" to={href} asModal {...{ key }}>
            {title}
          </Link>
        );
      } else if (item.type === "exercise") {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            {...{ key }}
          >
            <div
              style={{
                backgroundColor: "#ebebeb",
                borderRadius: 10,
                minHeight: 60,
                minWidth: 80,
                maxHeight: 60,
                maxWidth: 80,
                flex: 1,
                marginRight: 10,
                marginLeft: "-2em",
                marginBottom: 10,
              }}
              className="exercise-image-container"
            >
              <img
                className="exercise-gif"
                src={`/img/exercises/${item.value.slug}.gif`}
                alt="exercise demo"
              />
            </div>
            <div style={{ flexGrow: 2 }}>
              <Link
                to={`/exercise-library/${item.value.slug}`}
                className="light-link"
                asModal
              >
                {item.value.text}
              </Link>
            </div>
          </div>
        );
      } else if (item.type === "image") {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: "1rem",
              marginTop: "1rem",
            }}
            {...{ key }}
          >
            <img src={item.value} alt="workout" />
          </div>
        );
      }
      return <div {...{ key }}>Unknown</div>;
    });
    return (
      <div
        className="accordion-section"
        data-trigger={item.title}
        key={`${item.title}-${item.day}-${item.week}`}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            background: "transparent",
          }}
        >
          {nodes}
        </div>
      </div>
    );
  });

  return sections;
}
